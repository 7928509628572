// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconNoFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.8213 9.2325L9.22506 15.8287C9.05256 16.0012 9.08633 16.29 9.29258 16.4175C10.2863 17.0287 11.4938 17.3212 12.7838 17.1262C15.0001 16.7925 16.7851 15.0075 17.1188 12.795C17.3138 11.5087 17.0213 10.2975 16.4101 9.30375C16.2826 9.09375 15.9938 9.06 15.8213 9.2325Z" /><path d="M6.87754 11.2087C7.2113 8.99624 8.99256 7.215 11.2051 6.88125C12.4913 6.68625 13.6988 6.975 14.6963 7.5825C14.9026 7.71 14.9363 7.99875 14.7638 8.17125L8.17132 14.7637C7.99882 14.9362 7.71005 14.9025 7.58255 14.6963C6.9713 13.7025 6.68254 12.495 6.87754 11.2087Z" /><path fillRule="evenodd" clipRule="evenodd" d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.175 2.625 12 2.625ZM12 18.6825C8.31 18.6825 5.32127 15.69 5.32127 12.0038C5.32127 8.31375 8.31375 5.325 12 5.325C15.6863 5.325 18.6788 8.3175 18.6788 12.0038C18.6788 15.69 15.6863 18.6825 12 18.6825Z" /></svg>;
};