// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconHistory = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.2243 6.44635C16.7393 4.9951 14.7669 4.21886 12.7081 4.23386C8.42561 4.28261 4.98307 7.80386 5.02807 12.0826C5.03182 12.2814 5.04686 12.4839 5.06561 12.6826L3.26559 11.2689C2.86059 10.9501 2.26809 11.0176 1.94934 11.4264C1.63059 11.8314 1.69811 12.4239 2.10686 12.7426L5.53436 15.4389C5.70311 15.5739 5.90933 15.6414 6.11558 15.6414C6.33683 15.6414 6.56185 15.5626 6.7381 15.4051L9.99682 12.5064C10.3831 12.1614 10.4169 11.5689 10.0756 11.1826C9.73061 10.7964 9.13809 10.7626 8.75184 11.1039L6.94061 12.7164C6.94061 12.6939 6.94061 12.6751 6.94061 12.6526C6.91811 12.4576 6.90683 12.2589 6.90683 12.0639C6.87308 8.81636 9.48309 6.14636 12.7306 6.10886C14.3206 6.08261 15.7906 6.68636 16.9156 7.78886C18.0406 8.88761 18.6706 10.3614 18.6856 11.9364C18.7043 13.5114 18.1081 14.9964 17.0056 16.1214C15.9068 17.2464 14.4331 17.8764 12.8581 17.8914C11.4481 17.9214 10.0906 17.4189 9.01808 16.5226C8.62058 16.1926 8.02807 16.2414 7.69807 16.6389C7.36432 17.0364 7.41684 17.6289 7.81434 17.9589C9.20934 19.1251 10.9756 19.7664 12.7944 19.7664C12.8244 19.7664 12.8506 19.7664 12.8806 19.7664C14.9543 19.7439 16.8968 18.9151 18.3481 17.4301C19.7993 15.9489 20.5831 13.9876 20.5606 11.9139C20.5381 9.84011 19.7093 7.89385 18.2243 6.44635Z" /><path d="M12.6068 7.61637C12.0893 7.61637 11.6693 8.03637 11.6693 8.55387V11.5501C11.6693 12.1089 11.8718 12.6489 12.2243 13.0614L14.2231 15.4464C14.4106 15.6676 14.6731 15.7801 14.9431 15.7801C15.1568 15.7801 15.3706 15.7089 15.5431 15.5626C15.9406 15.2289 15.9931 14.6401 15.6593 14.2426L13.6531 11.8464C13.5818 11.7639 13.5443 11.6589 13.5443 11.5501V8.55387C13.5443 8.03262 13.1243 7.61637 12.6068 7.61637Z" /></svg>;
};