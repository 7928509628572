// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPhoto = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M18.8813 6.23816C16.6088 5.92691 14.2951 5.76941 12.0001 5.76941C9.70506 5.76941 7.39131 5.92691 5.11881 6.23816C4.62756 6.30566 4.23381 6.69566 4.16256 7.18691C3.69381 10.3782 3.69381 13.6182 4.16256 16.8094C4.23756 17.3007 4.63131 17.6907 5.12256 17.7582C7.39506 18.0694 9.70881 18.2269 12.0038 18.2269C14.2988 18.2269 16.6126 18.0694 18.8851 17.7582C19.3763 17.6907 19.7701 17.3007 19.8413 16.8094C20.3101 13.6182 20.3101 10.3782 19.8413 7.18691C19.7663 6.69566 19.3726 6.30566 18.8813 6.23816ZM4.86381 4.38191C7.23381 4.05566 9.61506 3.89441 12.0001 3.89441C14.3851 3.89441 16.7701 4.05566 19.1363 4.38191C20.4563 4.56191 21.4988 5.59691 21.6938 6.91691C22.1888 10.2882 22.1888 13.7119 21.6938 17.0832C21.5026 18.4032 20.4601 19.4382 19.1363 19.6182C16.7663 19.9444 14.3851 20.1057 12.0001 20.1057C9.61506 20.1057 7.23006 19.9444 4.86381 19.6182C3.54381 19.4382 2.50131 18.4032 2.30631 17.0832C1.81131 13.7119 1.81131 10.2882 2.30631 6.91691C2.49756 5.59691 3.54006 4.56191 4.86381 4.38191ZM7.69499 10.8469C8.55034 10.8469 9.24374 10.1535 9.24374 9.29814C9.24374 8.44279 8.55034 7.74939 7.69499 7.74939C6.83964 7.74939 6.14624 8.44279 6.14624 9.29814C6.14624 10.1535 6.83964 10.8469 7.69499 10.8469ZM12.0002 16.6782C14.0027 16.6782 16.0239 16.5544 18.0152 16.3069C18.2102 16.2844 18.3677 16.1269 18.3902 15.9319C18.5814 14.4094 18.6602 12.8794 18.6264 11.3532C18.6227 11.1169 18.5177 10.8919 18.3377 10.7307L15.9752 8.61192C15.7952 8.45067 15.5214 8.46942 15.3639 8.65317L10.0427 14.8369C9.8814 15.0207 9.60015 15.0357 9.4239 14.8669L8.01015 13.5244C7.8339 13.3557 7.55265 13.3707 7.3914 13.5544L5.69265 15.4932C5.6139 15.5832 5.5764 15.7032 5.58765 15.8232L5.59328 15.8757L5.5989 15.9282C5.6214 16.1269 5.77515 16.2844 5.9739 16.3069C7.96515 16.5544 9.9864 16.6782 11.9889 16.6782H12.0002Z" /></svg>;
};