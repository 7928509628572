// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconGlobe = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM4.5 12C4.5 10.8038 4.785 9.675 5.2875 8.67C5.4 8.445 5.6925 8.38875 5.88 8.5575C6.31125 8.95125 6.84 9.59251 6.825 10.3538C6.78375 12.2175 8.0475 13.4662 8.80125 14.2125C8.8875 14.2987 8.97 14.3775 9.045 14.4563C9.59625 15.0225 9.975 15.1538 10.3463 15.2812C10.5488 15.3525 10.7437 15.4163 11.0325 15.5813C12.1462 16.2188 12.2325 16.7738 12.1987 17.1338C12.1312 17.8763 12.2588 18.4913 12.4613 18.975C12.5663 19.2225 12.3975 19.4963 12.1313 19.5038C12.09 19.5038 12.045 19.5038 12.0038 19.5038C7.8675 19.5038 4.50375 16.14 4.50375 12.0038L4.5 12ZM18.0563 16.4175C17.9213 16.6012 17.6588 16.635 17.4938 16.4775C16.8375 15.8513 15.6863 14.97 14.37 14.9588C13.4138 14.9513 13.3538 14.8837 12.9713 14.4337C12.84 14.28 12.6713 14.085 12.4538 13.8638C12.3525 13.7663 12.2963 13.6725 12.2588 13.5975C12.8325 13.86 13.4663 13.8525 13.9838 13.5675C14.3738 13.3537 15.0375 12.7913 15.0938 11.4375C15.1125 11.025 15.255 10.7175 15.5138 10.5413C15.75 10.38 16.0088 10.3763 16.1175 10.4138C16.7025 10.6125 17.1 10.3313 17.265 10.0275C17.7413 9.14625 16.4438 7.68375 16.0688 7.39125C15.3563 6.8325 13.7513 6.56625 12.7463 7.0425C12.5363 7.14375 12.39 6.85875 12.5212 6.675C12.96 6.0525 13.4513 5.67375 13.6163 5.69625C14.2313 5.9925 14.655 5.745 14.8088 5.62125C14.8763 5.565 14.9363 5.505 14.9888 5.4375C15.0938 5.29875 15.2888 5.26125 15.4425 5.34C17.85 6.58875 19.5038 9.10125 19.5038 11.9962C19.5038 13.6462 18.9675 15.1763 18.06 16.4138L18.0563 16.4175Z" /></svg>;
};