// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChevronLeftSmall = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13.7169 17.1159C13.9306 17.3606 14.0291 17.684 13.9925 18.0046C13.956 18.3253 13.7816 18.6178 13.5144 18.8062C13.326 18.9412 13.1038 19.0059 12.8788 19.0059C12.5413 19.0059 12.1981 18.8596 11.9563 18.584L7.51815 13.4962C6.77565 12.6468 6.77565 11.3643 7.51815 10.515L11.99 5.38777C12.2066 5.13745 12.5216 4.99402 12.8535 4.99402C13.1094 4.99402 13.3625 5.08121 13.565 5.24152C13.7985 5.42714 13.9447 5.70277 13.9728 6.01496C14.0038 6.35246 13.8941 6.68995 13.6747 6.94308L9.3519 11.8987C9.29846 11.9606 9.29846 12.0534 9.3519 12.1125L13.7169 17.1159Z" /></svg>;
};