// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconAlertCircleFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM12 18.5025C11.115 18.5025 10.3987 17.7863 10.3987 16.9012C10.3987 16.0162 11.115 15.3 12 15.3C12.885 15.3 13.6012 16.0162 13.6012 16.9012C13.6012 17.7863 12.885 18.5025 12 18.5025ZM14.1787 7.29C14.1637 8.92875 13.95 10.5525 13.5487 12.1013L13.395 12.6938C13.2563 13.2262 12.9225 13.62 12.5175 13.7325C12.1763 13.8263 11.8237 13.8263 11.4787 13.7325C11.0737 13.62 10.74 13.2262 10.6012 12.6938L10.4475 12.1013C10.05 10.5563 9.83625 8.92875 9.8175 7.29C9.81 6.525 10.2 5.86125 10.7475 5.70375C11.5725 5.46375 12.4237 5.46375 13.245 5.70375C13.7925 5.865 14.1825 6.52875 14.175 7.29H14.1787Z" /></svg>;
};