// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconDudeFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM15.2438 8.175C15.8663 8.175 16.3688 8.6775 16.3688 9.3C16.3688 9.9225 15.8663 10.425 15.2438 10.425C14.6213 10.425 14.1187 9.9225 14.1187 9.3C14.1187 8.6775 14.6213 8.175 15.2438 8.175ZM8.76375 8.175C9.38625 8.175 9.88875 8.6775 9.88875 9.3C9.88875 9.9225 9.38625 10.425 8.76375 10.425C8.14125 10.425 7.63875 9.9225 7.63875 9.3C7.63875 8.6775 8.14125 8.175 8.76375 8.175ZM12.0037 17.6025L11.9775 17.5687L11.9513 17.6025C8.8425 17.7487 7.04625 15.42 7.20375 13.9275C7.3575 12.435 8.7675 12.36 9.65625 12.7613C11.1862 13.4513 12.9187 13.3238 14.2987 12.7575C15.2025 12.3863 16.5937 12.4237 16.7512 13.92C16.9087 15.4125 15.12 17.745 12.0075 17.6025H12.0037Z" /></svg>;
};