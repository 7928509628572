// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconTurnDownRight = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13.9173 11.7122V16.0322C13.9173 16.351 14.2885 16.5235 14.5323 16.321L16.9323 14.3147C17.3073 13.9997 17.8773 13.9847 18.226 14.326C18.631 14.7197 18.5973 15.3647 18.1735 15.7172L13.5798 19.561C13.231 19.8535 12.7248 19.8535 12.376 19.561L7.78228 15.7172C7.56103 15.5335 7.44478 15.2672 7.44478 14.9972C7.44478 14.7947 7.50854 14.5922 7.64354 14.4197C7.97354 13.9997 8.62227 13.9772 9.03477 14.3185L11.4235 16.3172C11.6673 16.5197 12.0385 16.3472 12.0385 16.0285V11.7085C12.0385 8.62598 9.54103 6.11723 6.46603 6.09473C5.98603 6.09098 5.54727 5.74223 5.49477 5.26598C5.43102 4.69973 5.87354 4.21973 6.42479 4.21973C10.561 4.22348 13.9173 7.58348 13.9173 11.7122Z" /></svg>;
};