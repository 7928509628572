// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconRocket = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.1425 12.4913C18.1275 12.1838 18.2062 11.8838 18.3787 11.6288C19.41 10.1025 20.0587 8.35504 20.6325 6.60379L21.6412 3.52504C21.8775 2.80504 21.195 2.12254 20.475 2.35879L17.3962 3.36754C15.6487 3.94129 13.8975 4.59004 12.3712 5.62129C12.1162 5.79379 11.8125 5.87254 11.5087 5.85754C7.89372 5.68504 4.40247 7.42879 2.39247 10.53C2.15997 10.8863 2.47497 11.3438 2.89122 11.2538C4.15872 10.9838 5.44497 10.89 6.72372 10.9688C7.02747 10.9875 7.22997 11.3063 7.11747 11.5913C6.96372 11.9813 6.78747 12.6188 6.66372 13.0988C6.58497 13.395 6.81747 13.6725 7.12497 13.6725C8.87622 13.6838 10.3162 15.1238 10.3275 16.875C10.3275 17.1825 10.605 17.415 10.9012 17.3363C11.3775 17.2125 12.0187 17.0363 12.4087 16.8825C12.6937 16.77 13.0125 16.9725 13.0312 17.2763C13.11 18.555 13.0162 19.8413 12.7462 21.1088C12.6562 21.525 13.1137 21.84 13.47 21.6075C16.5712 19.5975 18.315 16.1063 18.1425 12.4913ZM17.9962 6.00379C18.81 6.81754 18.81 8.14129 17.9962 8.95504C17.1825 9.76879 15.8587 9.76879 15.045 8.95504C14.2312 8.14129 14.2312 6.81754 15.045 6.00379C15.8587 5.19004 17.1825 5.19004 17.9962 6.00379ZM6.61872 14.4L6.69747 16.8338C6.70497 17.0888 6.91122 17.2913 7.16247 17.2988L9.59622 17.3775C9.83247 17.385 9.88497 17.655 9.71622 17.8238C8.01372 19.5263 5.48622 20.4188 3.57747 20.8875C3.28872 20.9588 3.03372 20.7038 3.10497 20.415C3.57372 18.51 4.52247 15.9225 6.16872 14.2763C6.33747 14.1075 6.60372 14.16 6.61497 14.3963L6.61872 14.4Z" /></svg>;
};