// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconSave = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.9972 15.0868C11.6447 15.0868 11.2922 14.9668 11.0034 14.7268L7.65465 11.9218C7.2309 11.5693 7.19714 10.9243 7.60214 10.5306C7.95464 10.1893 8.52467 10.2043 8.89967 10.5193L10.7522 12.0718C10.8722 12.1768 11.0597 12.0868 11.0597 11.9293V4.90932C11.0597 4.35807 11.5322 3.91932 12.0947 3.97557C12.5822 4.02432 12.9347 4.47057 12.9347 4.96182V11.9293C12.9347 12.0868 13.1184 12.1731 13.2422 12.0718L15.1359 10.4856C15.5597 10.1331 16.2009 10.2156 16.5159 10.6806C16.7896 11.0893 16.6734 11.6443 16.2984 11.9593L12.9909 14.7268C12.7021 14.9668 12.3497 15.0868 11.9972 15.0868Z" /><path d="M19.5009 15.3231C19.5685 14.8093 20.0409 14.4531 20.5547 14.5206C21.0684 14.5918 21.4284 15.0643 21.3647 15.5781C21.2934 16.0956 21.1397 16.5981 20.9072 17.0631C20.6785 17.5168 20.3822 17.9331 20.0222 18.2931C19.6622 18.6568 19.2497 18.9568 18.7959 19.1856C18.3309 19.4218 17.8322 19.5793 17.3147 19.6543C16.4409 19.7781 15.5484 19.8718 14.6634 19.9356C13.7822 19.9993 12.886 20.0293 12.001 20.0293C11.1159 20.0293 10.2197 19.9993 9.33841 19.9356C8.45341 19.8718 7.56093 19.7743 6.68718 19.6506C6.16968 19.5756 5.67095 19.4181 5.20595 19.1818C4.7522 18.9531 4.3397 18.6493 3.9797 18.2893C3.6197 17.9293 3.32345 17.5131 3.0947 17.0593C2.8622 16.5943 2.70842 16.0918 2.63717 15.5743C2.56592 15.0643 2.92592 14.5918 3.43967 14.5206C3.94967 14.4493 4.42219 14.8093 4.49344 15.3231C4.53469 15.6381 4.62844 15.9418 4.77094 16.2231C4.90969 16.5006 5.08966 16.7518 5.30716 16.9693C5.52466 17.1868 5.77595 17.3706 6.0497 17.5093C6.33095 17.6556 6.63467 17.7493 6.94967 17.7943C7.78217 17.9143 8.6297 18.0043 9.4697 18.0643C11.1422 18.1843 12.8522 18.1843 14.5247 18.0643C15.3647 18.0043 16.2122 17.9143 17.0447 17.7943C17.3597 17.7493 17.6634 17.6518 17.9447 17.5093C18.2184 17.3706 18.4697 17.1906 18.6872 16.9693C18.9047 16.7518 19.0847 16.5006 19.2235 16.2231C19.366 15.9418 19.4597 15.6381 19.5009 15.3231Z" /></svg>;
};