// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconLightbulb = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.2753 12.8195C17.4528 11.6945 18.1878 10.1083 18.1878 8.34956C18.1878 4.8283 15.244 1.99329 11.6815 2.16954C8.57279 2.32329 6.01159 4.85454 5.82409 7.96329C5.70784 9.84579 6.43906 11.5595 7.66906 12.7633C8.62906 13.7008 9.29283 14.882 9.56658 16.172C9.61158 16.3895 9.46905 16.5958 9.25155 16.6296C9.1878 16.6408 9.12404 16.6483 9.06029 16.6595C8.91779 16.682 8.79777 16.7908 8.76027 16.9296C8.46027 18.0471 8.46027 19.2208 8.76027 20.3345C8.79777 20.4733 8.91779 20.5821 9.06029 20.6046C9.34904 20.6533 9.63406 20.6908 9.92281 20.7208C10.0691 20.7358 10.1891 20.8333 10.2378 20.972L10.4066 21.4783C10.4478 21.6058 10.5603 21.7033 10.6916 21.7258C11.5578 21.8758 12.4428 21.8758 13.3091 21.7258C13.4441 21.7033 13.5528 21.6058 13.5941 21.4783L13.7628 20.972C13.8078 20.8333 13.9316 20.7358 14.0778 20.7208C14.3666 20.6871 14.6554 20.6496 14.9404 20.6046C15.0829 20.5821 15.2028 20.4733 15.2403 20.3345C15.5403 19.217 15.5403 18.0433 15.2403 16.9258C15.2028 16.7871 15.0829 16.6783 14.9404 16.6558C14.8691 16.6445 14.7978 16.6333 14.7266 16.6221C14.5091 16.5921 14.3666 16.382 14.4116 16.1683C14.6816 14.9008 15.3228 13.727 16.2753 12.8195ZM13.0503 5.28581C12.9266 5.30081 12.8028 5.31955 12.6791 5.34205C10.8716 5.6908 9.4203 7.1383 9.07155 8.94955C9.04905 9.0733 9.03034 9.19704 9.01534 9.32079C8.92534 10.127 7.78155 10.2508 7.5678 9.46705C7.3953 8.83705 7.35781 8.15081 7.48156 7.44206C7.80781 5.58206 9.30409 4.08581 11.1678 3.75581C11.8766 3.63206 12.5628 3.66955 13.1928 3.84205C13.9803 4.0483 13.8566 5.19206 13.0503 5.28581Z" /></svg>;
};