// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconClockFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM16.02 17.2763C15.8437 17.43 15.6263 17.505 15.4088 17.505C15.1463 17.505 14.8837 17.3962 14.7 17.1787L11.3738 13.3275C10.9313 12.8175 10.6875 12.165 10.6875 11.49V6.25126C10.6875 5.73376 11.1075 5.31376 11.625 5.31376C12.1425 5.31376 12.5625 5.73376 12.5625 6.25126V11.49C12.5625 11.715 12.645 11.9325 12.7913 12.105L16.1175 15.9563C16.455 16.3463 16.4137 16.9388 16.02 17.2763Z" /></svg>;
};