// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconUserAdd = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.69359 10.4682C6.86234 10.4682 4.55984 8.16565 4.55984 5.3344C4.55984 2.50315 6.86234 0.196899 9.69359 0.196899C12.5248 0.196899 14.8273 2.4994 14.8273 5.33065C14.8273 8.1619 12.5248 10.4644 9.69359 10.4644V10.4682Z" /><path d="M10.0236 18.2269C10.0236 17.6719 10.2411 17.1694 10.5973 16.8019C10.9723 16.4119 11.5011 16.1644 12.0861 16.1644H13.6836C13.8898 16.1644 14.0586 15.9957 14.0586 15.7894V14.1919C14.0586 13.5732 14.3323 13.0182 14.7636 12.6394C15.1273 12.3207 15.5998 12.1294 16.1211 12.1294C16.1436 12.1294 16.1623 12.1294 16.1848 12.1294C16.3536 12.1369 16.4886 11.9907 16.4661 11.8257C16.4661 11.8182 16.4661 11.8144 16.4661 11.8069C16.2561 10.6181 14.8311 9.99565 13.8186 10.7644C12.6748 11.6344 11.2498 12.1557 9.70109 12.1557C8.15234 12.1557 6.73109 11.6382 5.58734 10.7682C4.44359 9.89815 2.90234 10.7306 2.90234 12.1069V12.1444C2.90234 15.8944 5.94359 18.9357 9.69359 18.9357C9.69359 18.9357 9.69359 18.9357 9.69734 18.9357C9.91484 18.9357 10.0761 18.7369 10.0461 18.5232C10.0311 18.4256 10.0236 18.3282 10.0236 18.2269Z" /><path d="M20.1373 17.2894H17.4373C17.2311 17.2894 17.0623 17.1207 17.0623 16.9144V14.2144C17.0623 13.6894 16.6311 13.2469 16.1061 13.2544C15.5811 13.2619 15.1873 13.6782 15.1873 14.1919V16.9144C15.1873 17.1207 15.0186 17.2894 14.8123 17.2894H12.1123C11.5873 17.2894 11.1448 17.7207 11.1523 18.2457C11.1598 18.7707 11.5761 19.1644 12.0898 19.1644H14.8123C15.0186 19.1644 15.1873 19.3332 15.1873 19.5394V22.2394C15.1873 22.7644 15.6186 23.2069 16.1436 23.1994C16.6686 23.1919 17.0623 22.7757 17.0623 22.2619V19.5394C17.0623 19.3332 17.2311 19.1644 17.4373 19.1644H20.1598C20.6698 19.1644 21.0861 18.7557 21.0973 18.2457C21.1086 17.7357 20.6661 17.2894 20.1373 17.2894Z" /></svg>;
};