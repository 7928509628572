// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconBox = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 4.875C13.725 4.875 15.465 4.99125 17.1713 5.2275C18.0038 5.34 18.6601 6 18.7763 6.8325C19.2451 10.2638 19.2451 13.7438 18.7763 17.175C18.6638 18.0075 18.0038 18.6638 17.1713 18.78C15.465 19.0125 13.725 19.1325 12 19.1325C10.275 19.1325 8.53511 19.0163 6.82886 18.78C5.99636 18.6675 5.3401 18.0075 5.22385 17.175C4.7551 13.7438 4.7551 10.2638 5.22385 6.8325C5.33635 6 5.99636 5.34375 6.82886 5.2275C8.53886 4.99125 10.2788 4.875 12 4.875ZM12 3C10.1888 3 8.37379 3.12375 6.57754 3.3675C4.90879 3.59625 3.59637 4.90875 3.37137 6.57375C2.88012 10.1737 2.88012 13.8225 3.37137 17.4225C3.60012 19.0912 4.91254 20.4037 6.57754 20.6287C8.37754 20.8725 10.1888 20.9962 12 20.9962C13.8113 20.9962 15.6264 20.8725 17.4226 20.6287C19.0914 20.4 20.4038 19.0875 20.6288 17.4225C21.12 13.8225 21.12 10.1737 20.6288 6.57375C20.4 4.905 19.0914 3.5925 17.4226 3.3675C15.6264 3.12 13.815 3 12 3Z" /></svg>;
};