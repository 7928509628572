// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconRepeat = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.7583 7.92068C20.5258 6.58568 19.1984 5.53567 17.5034 5.35567C16.0859 5.20192 14.6571 5.11193 13.2284 5.07818C13.0709 5.07443 12.9884 4.89443 13.0896 4.77443L13.6371 4.11818C13.9521 3.74318 13.9671 3.17317 13.6221 2.82442C13.2284 2.42317 12.5796 2.45318 12.2308 2.87693L10.3709 5.10818C9.95461 5.61068 9.95837 6.33818 10.3784 6.84068L12.2909 9.11693C12.4746 9.33818 12.7409 9.45068 13.0109 9.45068C13.2134 9.45068 13.4158 9.38693 13.5883 9.25193C14.0083 8.92193 14.0308 8.27317 13.6896 7.86442L13.1871 7.26818C13.0821 7.14442 13.1759 6.95317 13.3371 6.95692C14.6646 6.99067 15.9959 7.07692 17.3084 7.21942C18.2196 7.31692 18.8421 7.81567 18.9134 8.23942C19.3746 10.9057 19.3746 13.6132 18.9134 16.2794C18.8384 16.7032 18.2196 17.2019 17.3084 17.2994C15.5571 17.4869 13.7759 17.5844 12.0059 17.5844C10.2396 17.5844 8.4546 17.4907 6.70335 17.2994C5.7921 17.2019 5.16963 16.7032 5.09838 16.2794C4.63713 13.6132 4.63713 10.9057 5.09838 8.23942C5.17338 7.81567 5.7921 7.31692 6.70335 7.21942C7.48335 7.13692 8.27086 7.06943 9.05836 7.02443C9.20086 7.01693 9.27961 6.86318 9.20461 6.74318C9.08836 6.55943 8.95713 6.30817 8.93838 6.09442C8.91963 5.86942 8.95335 5.64068 9.0471 5.43068C9.1071 5.29568 9.01335 5.14567 8.8671 5.15692C8.07585 5.20567 7.28462 5.27317 6.49712 5.35567C4.80587 5.53942 3.4746 6.58568 3.2421 7.92068C2.74335 10.8007 2.74335 13.7219 3.2421 16.5982C3.4746 17.9332 4.80212 18.9832 6.49712 19.1632C8.32337 19.3582 10.1608 19.4594 11.9983 19.4594C13.8358 19.4594 15.6734 19.3619 17.4996 19.1632C19.1909 18.9794 20.5221 17.9332 20.7546 16.5982C21.2571 13.7182 21.2571 10.8007 20.7583 7.92068Z" /></svg>;
};